<template>
  <div v-if="this.isAdmin == false"> {{ this.$NOLIMITResource[this.dataUser.language].PermissionPage }}</div> 
  <!--begin::Content wrapper-->
  <div v-else class="d-flex flex-column flex-column-fluid">
    <!--begin::Toolbar-->
    <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6" style="padding-top: 0px !important;padding-bottom: 10px !important;">
      <!--begin::Toolbar container-->
      <div
        id="kt_app_toolbar_container"
        class="app-container  d-flex flex-stack"
      >
        <!--begin::Page title-->
        <div
          class="page-title d-flex flex-column justify-content-center flex-wrap me-3"
        >
          <!--begin::Title-->
          <h1
            class="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0"
          >
          {{ this.$NOLIMITResource[this.dataUser.language].TopUpAccount.Title }}
          </h1>
          <!--end::Title-->
        </div>
        <!--end::Page title-->
      </div>
      <!--end::Toolbar container-->
    </div>
    <!--end::Toolbar-->
    <!--begin::Content-->
    <div id="kt_app_content" class="app-content flex-column-fluid">
      <!--begin::Content container-->
      <div id="kt_app_content_container" class="app-container ">
        <!--begin::Card-->
        <div class="card">
          <!--begin::Card header-->
          <div class="card-header border-0 pt-6" style="align-items: center;padding-top: 0px !important;">
            <!--begin::Card title-->
            <div class="card-title">
              <!--begin::Search-->
              <div class="d-flex align-items-center position-relative my-1">
                <i class="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                  <span id="searchClick" class="path1" @click="onEnterSearchFirst" style="cursor:pointer;z-index: 2;">
                  </span>
                  <span class="path2"></span>
                </i>
                <input
                  v-on:keyup.enter="onEnterSearchFirst"
                  ref="inputFieldSearch"
                  v-model="inputValue"
                  type="text"
                  class="form-control form-control-solid w-350px ps-12"
                  :placeholder="this.$NOLIMITResource[this.dataUser.language].TopUpAccount.SearhTextAccountant"
                />
              </div> 
              <!--end::Search-->
            </div>  
            <!--begin::Card toolbar-->
            <div class="card-toolbar" style="margin-left: auto;">
              <!--begin::Toolbar-->
              <div class="content__toolbar--table">
                  <a @click="exportCSV()" id="selectedall__toolbar"> {{ this.$NOLIMITResource[this.dataUser.language].DownloadCSV }}</a>
              </div>
              <div
                class="d-flex justify-content-end"
                data-kt-customer-table-toolbar="base"
              >
                <!--begin::Filter-->
													<button @click="toggleMenuFilter" type="button" class="btn btn-light-primary me-3 " data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
													<i class="ki-duotone ki-filter fs-2">
														<span class="path1"></span>
														<span class="path2"></span>
													</i>{{ this.$NOLIMITResource[this.dataUser.language].FilterSearch }}</button>
													<!--begin::Menu 1-->
													<div class="menu menu-sub menu-sub-dropdown w-300px w-md-325px  show-dropfilter" :class="{show:this.onShowMenuFilter == true}"  data-kt-menu="true" id="kt-toolbar-filter" data-select2-id="select2-data-kt-toolbar-filter" style="">
														<!--begin::Header-->
														<div class="px-7 py-5">
															<div class="fs-4 text-gray-900 fw-bold">{{ this.$NOLIMITResource[this.dataUser.language].FilterOptions }}</div>
														</div>
														<!--end::Header-->
														<!--begin::Separator-->
                            
														<div class="separator border-gray-200"></div>
														<!--end::Separator-->
                            <form  
                                action=""
                                @submit.prevent="onFilterSearchFirst">
														<!--begin::Content-->
														<div class="px-7 py-5" data-select2-id="select2-data-128-13oj">
                              <!--begin::Input group-->
                              <div>
                                <label class="form-label fs-5 fw-semibold mb-3">{{ this.$NOLIMITResource[this.dataUser.language].StartDate }}:</label>
                                <v-date-picker class="inline-block h-full" v-model="dateStart" :timezone="timezone2">
                                  <template v-slot="{ inputValue, togglePopover }">
                                    <div class="flex items-center">
                                      <input @click="togglePopover()" :value="inputValue" class="form-control form-control-solid flatpickr-input active" name="calendar_event_end_date" :placeholder="this.$NOLIMITResource[this.dataUser.language].StartDate" id="kt_calendar_datepicker_end_date" type="text" readonly="readonly" style="margin-bottom:20px">
                                    </div>
                                  </template>
                                </v-date-picker>
                              </div>
                              <!--end::Input group-->

                              <!--begin::Input group-->
                              <div>
                                <label class="form-label fs-5 fw-semibold mb-3">{{ this.$NOLIMITResource[this.dataUser.language].EndDate }}:</label>
                                <v-date-picker class="inline-block h-full" v-model="dateEnd" :timezone="timezone1">
                                  <template v-slot="{ inputValue, togglePopover }">
                                    <div class="flex items-center">
                                      <input @click="togglePopover()" :value="inputValue" class="form-control form-control-solid flatpickr-input active" name="calendar_event_end_date" :placeholder="this.$NOLIMITResource[this.dataUser.language].EndDate" id="kt_calendar_datepicker_end_date" type="text" readonly="readonly" style="margin-bottom:20px">
                                    </div>
                                  </template>
                                </v-date-picker>
                              </div>
                              <!--end::Input group-->

															<!--begin::Input group-->
															<div class="mb-10">
																<!--begin::Label-->
																<label class="form-label fs-5 fw-semibold mb-3">{{ this.$NOLIMITResource[this.dataUser.language].Status }}:</label>
																<!--end::Label-->
																<!--begin::Options-->
																<div class="d-flex flex-column flex-wrap fw-semibold" data-kt-customer-table-filter="payment_type">
																	<!--begin::Option-->
																	<label class="form-check form-check-sm form-check-custom form-check-solid mb-7 me-5">
																		<input class="form-check-input" type="radio" name="payment_type" value="Thành công" v-model="this.status">
                                    <div>
                                      <span class="form-check-label text-gray-600">{{ this.$NOLIMITResource[this.dataUser.language].Success }} </span><br>
                                      <span class="form-check-label text-gray-600" style="font-size: 12px;">{{ this.$NOLIMITResource[this.dataUser.language].TopUpAccount.NumberOfTopups }}: {{ this.totalStatusSuccess }}</span>
                                    </div> 
																	</label>
																	<!--end::Option-->
																	<!--begin::Option-->
																	<label class="form-check form-check-sm form-check-custom form-check-solid mb-7 me-5">
																		<input class="form-check-input" type="radio" name="payment_type" value="Đang xử lý" v-model="this.status">
																		<div>
                                      <span class="form-check-label text-gray-600">{{ this.$NOLIMITResource[this.dataUser.language].Processing }}</span><br>
                                      <span class="form-check-label text-gray-600" style="font-size: 12px;">{{ this.$NOLIMITResource[this.dataUser.language].TopUpAccount.NumberOfTopups }}: {{ this.totalStatusProcessing }}</span>
                                    </div> 
																	</label>
																	<!--end::Option-->
                                   <!--begin::Option-->
																	<label class="form-check form-check-sm form-check-custom form-check-solid mb-7 me-5">
																		<input class="form-check-input" type="radio" name="payment_type" value="Thất bại" v-model="this.status">
																		<div>
                                      <span class="form-check-label text-gray-600">{{ this.$NOLIMITResource[this.dataUser.language].Failed }}</span><br>
                                      <span class="form-check-label text-gray-600" style="font-size: 12px;">{{ this.$NOLIMITResource[this.dataUser.language].TopUpAccount.NumberOfTopups }}: {{ this.totalStatusFailed }}</span>
                                    </div> 
																	</label>
																	<!--end::Option-->
																</div>
																<!--end::Options-->
															</div>
															<!--end::Input group-->
															<!--begin::Actions-->
															<div class="d-flex justify-content-end">
																<button @click="onResetFilter" type="reset" class="btn btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true" data-kt-customer-table-filter="reset">{{ this.$NOLIMITResource[this.dataUser.language].Reset }}</button>
																<button type="submit" class="btn btn-primary" data-kt-menu-dismiss="true" data-kt-customer-table-filter="filter">{{ this.$NOLIMITResource[this.dataUser.language].Filter }}</button>
															</div>
															<!--end::Actions-->
														</div>
														<!--end::Content-->
                          </form>
													</div>
													<!--end::Menu 1-->
                        <!--end::Filter-->
                          <!--begin::Add ads-->
                <!--end::Add ads-->
                <button @click="openModalAddManyTopup" type="button" class="btn btn-primary" style="margin-right: 10px;">
                  {{ this.$NOLIMITResource[this.dataUser.language].TopUpAccount.AddBatchTopup }}
                </button>
                <button @click="openModalAddTopup" type="button" class="btn btn-primary">
                  {{ this.$NOLIMITResource[this.dataUser.language].TopUpAccount.AddTopup }}
                </button>
                
              </div>
              <!--end::Toolbar-->
            </div>
            
            <!--end::Card toolbar-->
          </div>
          <!--end::Card header-->
          <!--begin::Card body-->
          <div class="card-body pt-0" style="margin-top: 20px;">
            <!--begin::Table-->
            <table
              class="table align-middle table-row-dashed fs-6 gy-5"
              id="kt_customers_table"
            >
              <thead>
                <tr
                  class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0"
                >
                    <template v-for="(column, index) in columns" :key="index" >
                      <th class="min-w-125px" :class="{
                        sorting_desc: this.sortingNumber == index,
                        sorting_asc: this.sortingAsc == index,
                      }" :style="{ 'width': column.width + 'px !important'}">
                          <div @mousedown="startResize($event, index)"><span
                            @click="
                              onSorting(index, column.property, 'changeSort')
                            "
                            >{{ column.label }}</span
                          ></div>
                      </th>
                    </template>
                    <th class="text-end min-w-70px"> {{ this.$NOLIMITResource[this.dataUser.language].Feature }}</th>
                </tr>
              </thead>
              <div class="loading-1" v-if="onLoading" >
                <span class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </span>
              </div>
              <div style="margin-top: 20px;" v-if="!this.onLoading && this.hasData == false">{{ this.$NOLIMITResource[this.dataUser.language].TopUpAccount.Nodata }}</div>   
              <tbody v-if="!this.onLoading && this.hasData == true" class="fw-semibold text-gray-600">
                <tr v-for="topUpOrder in topUpOrders" :key="topUpOrder.topupOrderId">
                  <td>
                    <a
                      class="text-gray-800 text-hover-primary mb-1"
                      >{{ topUpOrder.topupOrderId }}</a
                    >
                  </td>
                  <td>
                    <a class="text-gray-600 text-hover-primary mb-1">{{
                      topUpOrder.facebookAdsId  
                    }}</a>
                  </td>
                  <td>{{ topUpOrder.note }} <p v-if="topUpOrder.note == '' || topUpOrder.note == null">_</p></td>
                  <td>{{ topUpOrder.userId }}</td>
                    <td>{{ topUpOrder.userName }}</td>
                    <td>{{ formatCurrency(topUpOrder.amount) }} {{ topUpOrder.currency }}</td>
                    <td>{{ formatCurrency(topUpOrder.fee) }} VND</td>
                    <td>{{ formatCurrency(topUpOrder.amountDisplay) }} VND</td>
                  <td>
                    <a class="text-gray-600 text-hover-primary mb-1">
                        <div
                        v-if="topUpOrder.status == 'Thất bại'"
                          :class="`badge badge-light-danger`"
                        >
                        {{ this.$NOLIMITResource[this.dataUser.language].Failed }}
                        </div>
                      </a>
                    <a class="text-gray-600 text-hover-primary mb-1">
                      <div
                      v-if="topUpOrder.status == 'Thành công'"
                        :class="`badge badge-light-success`"
                      >
                      {{ this.$NOLIMITResource[this.dataUser.language].Success }}
                      </div>
                    </a>
                    <a class="text-gray-600 text-hover-primary mb-1">
                      <div
                      v-if="topUpOrder.status == 'Đang xử lý'"
                        :class="`badge badge-light-warning`"
                      >
                      {{ this.$NOLIMITResource[this.dataUser.language].Processing }}
                      </div>
                    </a>
                  </td>
                  <td>{{ formatCurrency(topUpOrder.limitBefore) }} {{ topUpOrder.currency }}</td>
                  <td>{{formatCurrency(topUpOrder.limitAfter) }} {{ topUpOrder.currency }}</td>
                  <td>{{ formatDateTime(topUpOrder.createDate) }}</td>

                  <td
                    class="text-end"
                    :class="{
                      active__update__combobox:
                        onShowMenu && currenOnshowId === topUpOrder.topupOrderId,
                    }" style="min-width:88px"
                  >
                    <a
                    @click="toggleMenuItem(topUpOrder.topupOrderId)"
                      class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary show menu-dropdown"
                      data-kt-menu-trigger="click"
                      data-kt-menu-placement="bottom-end"
                      > {{ this.$NOLIMITResource[this.dataUser.language].SeeDetail }}<i class="ki-duotone ki-down fs-5 ms-1"></i
                    ></a>
                    <!--begin::Menu-->
                    <div
                      class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4"
                      :class="{
                        show: onShowMenu && currenOnshowId === topUpOrder.topupOrderId,
                      }"
                     
                      data-kt-menu="true"
                      @mouseleave="closeMenuItem"
                    >
                      <!--begin::Menu item-->
                      <div class="menu-item px-3">
                        <router-link
                          :to="{name:'TopUpListDetail', query:{idTopUpOrder: topUpOrder.topupOrderId}}"
                          class="menu-link px-3"
                          >{{ this.$NOLIMITResource[this.dataUser.language].Detail }}</router-link
                        >
                      </div>
                      <!--end::Menu item-->
                       <!--begin::Menu item-->
                       <div class="menu-item px-3">
                        <a
                          @click="openModalUpdateTopUp(topUpOrder.topupOrderId)"
                          class="menu-link px-3"
                          >{{ this.$NOLIMITResource[this.dataUser.language].TopUpAccount.UpdateTopup }}</a
                        >
                      </div>
                      <!--end::Menu item-->
                       <!--begin::Menu item-->
                       <div class="menu-item px-3">
                        <a
                          @click="confirmReturnDeleteTopup(topUpOrder.topupOrderId)"
                          class="menu-link px-3"
                          >{{ this.$NOLIMITResource[this.dataUser.language].TopUpAccount.DeleteTopup }}</a
                        >
                      </div>
                      <!--end::Menu item-->
                    </div>
                    <!--end::Menu-->
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="row">
              <div
                class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"
              >
                <div class="dataTables_length" id="kt_customers_table_length">
                  <label>
                    <v-select v-model="this.pageSize" id="styleSelect" :options="numberPerPage" label="value" ></v-select>
                  </label>
                </div>
              </div>
              <div
                class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"
              >
                <div
                  class="dataTables_paginate paging_simple_numbers"
                  id="kt_customers_table_paginate"
                >
                  <ul v-if="this.totalPage >= 4" class="pagination">
                    <li
                      class="paginate_button page-item previous"
                      :class="{ disabled: this.pageNumber == 1 }"
                      id="kt_customers_table_previous"
                    >
                      <a
                        @click="prevPage"
                                                aria-controls="kt_customers_table"
                        data-dt-idx="0"
                        tabindex="0"
                        class="page-link"
                        ><i class="previous"></i
                      ></a>
                    </li>
                    <li
                      v-for="index in 4"
                      :key="index"
                      class="paginate_button page-item"
                      :class="{ active: pageNumber == this.firstPage + index }"
                    >
                      <a
                        @click="selectPage(this.firstPage + index)"
                                                aria-controls="kt_customers_table"
                        data-dt-idx="1"
                        tabindex="0"
                        class="page-link"
                        >{{ this.firstPage + index }}</a
                      >
                    </li>
                    <li
                      class="paginate_button page-item next"
                      :class="{ disabled: this.pageNumber == this.totalPage }"
                      id="kt_customers_table_next"
                    >
                      <a
                        @click="nextPage"
                                                aria-controls="kt_customers_table"
                        data-dt-idx="5"
                        tabindex="0"
                        class="page-link"
                        ><i class="next"></i
                      ></a>
                    </li>
                  </ul>
                  <ul v-else class="pagination">
                    <li
                      class="paginate_button page-item previous"
                      :class="{ disabled: this.pageNumber == 1 }"
                      id="kt_customers_table_previous"
                    >
                      <a
                        @click="prevPage"
                                                aria-controls="kt_customers_table"
                        data-dt-idx="0"
                        tabindex="0"
                        class="page-link"
                        ><i class="previous"></i
                      ></a>
                    </li>
                    <li
                      v-for="index in totalPage"
                      :key="index"
                      class="paginate_button page-item"
                      :class="{ active: pageNumber == this.firstPage + index }"
                    >
                      <a
                        @click="selectPage(this.firstPage + index)"
                                                aria-controls="kt_customers_table"
                        data-dt-idx="1"
                        tabindex="0"
                        class="page-link"
                        >{{ this.firstPage + index }}</a
                      >
                    </li>
                    <li
                      class="paginate_button page-item next"
                      :class="{ disabled: this.pageNumber == this.totalPage }"
                      id="kt_customers_table_next"
                    >
                      <a
                        @click="nextPage"
                                                aria-controls="kt_customers_table"
                        data-dt-idx="5"
                        tabindex="0"
                        class="page-link"
                        ><i class="next"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!--end::Table-->
          </div>
          <!--end::Card body-->
        </div>
        <!--end::Card-->
        <!--begin::Modals-->
        <!--begin::Modal - Update - TopupOrder-->
        <div
          class="modal fade show"
          :class="{ block: isShowModalUpdateTopup }"
          id="kt_modal_add_customer"
          tabindex="-1"
          aria-hidden="true"
        >
          <!--begin::Modal dialog-->
          <div class="modal-dialog modal-dialog-centered mw-650px">
            <!--begin::Modal content-->
            <div class="modal-content">
              <!--begin::Form-->
              <form
                class="form"
                id="kt_modal_add_customer_form"
                action=""
                @submit.prevent="updateTopUp"
              >
                <!--begin::Modal header-->
                <div class="modal-header" id="kt_modal_add_customer_header">
                  <!--begin::Modal title-->
                  <h2 class="fw-bold"> {{ this.$NOLIMITResource[this.dataUser.language].TopUpAccount.UpdateTopup }} {{ this.currentUpdateTopupId }}</h2>
                  <!--end::Modal title-->
                  <!--begin::Close-->
                  <div
                    @click="closeModalUpdateTopUp"
                    id="kt_modal_add_customer_close"
                    class="btn btn-icon btn-sm btn-active-icon-primary"
                  >
                    <i class="ki-duotone ki-cross fs-1">
                      <span class="path1"></span>
                      <span class="path2"></span>
                    </i>
                  </div>
                  <!--end::Close-->
                </div>
                <!--end::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body py-10 px-lg-17">
                  <!--begin::Scroll-->
                  <div
                    class="scroll-y me-n7 pe-7"
                    id="kt_modal_add_customer_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_add_customer_header"
                    data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
                    data-kt-scroll-offset="300px"
                  >
                   <!--begin::Input group-->
                  <div class="d-flex flex-column mb-7 fv-row">
                    <!--begin::Label-->
                    <label
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span>{{ this.$NOLIMITResource[this.dataUser.language].Note }}</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        :title="this.$NOLIMITResource[this.dataUser.language].TopUpAccount.TitleNoteTopup"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                    <!--end::Label-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="card_name"
                      v-model="topupCurrent.note"
                    />
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="d-flex flex-column mb-7 fv-row">
                    <!--begin::Label-->
                    <label
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span> {{ this.$NOLIMITResource[this.dataUser.language].Amount }}</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        :title="this.$NOLIMITResource[this.dataUser.language].TopUpAccount.AmountTopup"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                    <!--end::Label-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="card_name"
                      v-model="topupCurrent.amount"
                    />
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="d-flex flex-column mb-7 fv-row">
                    <!--begin::Label-->
                    <label
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span> {{ this.$NOLIMITResource[this.dataUser.language].TopupFee }}</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        :title="this.$NOLIMITResource[this.dataUser.language].TopupFee"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                    <!--end::Label-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="card_name"
                      v-model="topupCurrent.fee"
                    />
                  </div>
                  <!--end::Input group-->

                  <!--begin::Input group-->
                  <div class="d-flex flex-column mb-7 fv-row">
                    <!--begin::Label-->
                    <label
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span> {{ this.$NOLIMITResource[this.dataUser.language].CreatedDate }}</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        :title="this.$NOLIMITResource[this.dataUser.language].TopUpAccount.TitleCreatedTopup"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                    <!--end::Label-->
                    <div>
                      <v-date-picker class="inline-block h-full" v-model="topupCurrent.createDate" :timezone="timezone3" is24hr mode="dateTime">
                        <template v-slot="{ inputValue, togglePopover }">
                          <div class="flex items-center">
                            <input @click="togglePopover()" :value="inputValue" class="form-control form-control-solid flatpickr-input active" name="calendar_event_end_date" :placeholder="this.$NOLIMITResource[this.dataUser.language].CreatedDate" id="kt_calendar_datepicker_end_date" type="text" readonly="readonly" style="margin-bottom:20px">
                          </div>
                        </template>
                      </v-date-picker>
                    </div>
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="d-flex flex-column mb-7 fv-row">
                    <!--begin::Label-->
                    <label
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span> {{ this.$NOLIMITResource[this.dataUser.language].Currency }}</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        :title="this.$NOLIMITResource[this.dataUser.language].Currency"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                    <!--end::Label-->
                    <v-select id="styleSelect" v-model="topupCurrent.currency" :options="this.listCurrency" label="status"></v-select>
                  </div>
                  <!--end::Input group-->

                  <!--begin::Input group-->
                  <div class="d-flex flex-column mb-7 fv-row">
                    <!--begin::Label-->
                    <label
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span> {{ this.$NOLIMITResource[this.dataUser.language].Status }}</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        :title="this.$NOLIMITResource[this.dataUser.language].TopUpAccount.TitleStatusTopup"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                    <!--end::Label-->
                    <v-select id="styleSelect" v-model="topupCurrent.status" :options="this.listStatus" label="status"></v-select>
                  </div>
                  <!--end::Input group-->

                  <!--begin::Input group-->
                  <div class="d-flex flex-column mb-7 fv-row">
                    <!--begin::Label-->
                    <label
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span>{{ this.$NOLIMITResource[this.dataUser.language].LimitBeforeTopup }}</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        :title="this.$NOLIMITResource[this.dataUser.language].LimitBeforeTopup"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                    <!--end::Label-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="card_name"
                      v-model="topupCurrent.limitBefore"
                    />
                  </div>
                  <!--end::Input group-->


                   <!--begin::Input group-->
                   <div class="d-flex flex-column mb-7 fv-row">
                    <!--begin::Label-->
                    <label
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span>{{ this.$NOLIMITResource[this.dataUser.language].LimitAfterTopup }}</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        :title="this.$NOLIMITResource[this.dataUser.language].LimitAfterTopup"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                    <!--end::Label-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="card_name"
                      v-model="topupCurrent.limitAfter"
                    />
                  </div>
                  <!--end::Input group-->
                  </div>
                  <!--end::Scroll-->
                </div>
                <!--end::Modal body-->
                <!--begin::Modal footer-->
                <div class="modal-footer flex-center">
                  <!--begin::Button-->
                  <button
                    @click="closeModalUpdateTopUp"
                    type="reset"
                    id="kt_modal_add_customer_cancel"
                    class="btn btn-light me-3"
                  >
                  {{ this.$NOLIMITResource[this.dataUser.language].Cancel }}
                  </button>
                  <!--end::Button-->
                  <!--begin::Button-->
                  <button
                    type="submit"
                    id="kt_modal_add_customer_submit"
                    class="btn btn-primary"
                  >
                    <span class="indicator-label">{{ this.$NOLIMITResource[this.dataUser.language].Save }}</span>
                    <span class="indicator-progress"
                      >Please wait...
                      <span
                        class="spinner-border spinner-border-sm align-middle ms-2"
                      ></span
                    ></span>
                  </button>
                  <!--end::Button-->
                </div>
                <!--end::Modal footer-->
              </form>
              <!--end::Form-->
            </div>
          </div>
          
        </div>
        <!--end::Modal - update - topupOrders-->
        <!--begin::Modal - add topUpOrders-->
        <div
          class="modal fade show"
          :class="{ block: isShowModalAddTopup }"
          id="kt_modal_new_card"
          role="dialog"
          tabindex="-1"
          aria-modal="true"
        >
          <!--begin::Modal dialog-->
          <div class="modal-dialog modal-dialog-centered mw-650px">
            <!--begin::Modal content-->
            <div class="modal-content">
              <!--begin::Modal header-->
              <div class="modal-header">
                <!--begin::Modal title-->
                <h2> {{ this.$NOLIMITResource[this.dataUser.language].TopUpAccount.AddTopup }}</h2>

                <div
                  @click="closeModalAddTopup"
                  class="btn btn-sm btn-icon btn-active-color-primary"
                  data-bs-dismiss="modal"
                >
                  <i class="ki-duotone ki-cross fs-1">
                    <span class="path1"></span>
                    <span class="path2"></span>
                  </i>
                </div>
              </div>
              <!--end::Modal header-->
              <!--begin::Modal body-->
              <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
                <!--begin::Form-->
                <form
                  id="kt_modal_new_card_form"
                  class="form"
                  action=""
                  @submit.prevent="addTopup"
                >
                  <!--begin::Input group-->
                  <div class="d-flex flex-column mb-7 fv-row">
                    <!--begin::Label-->
                    <label
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span>AdsId</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        title="Id ads của topup"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                    <!--end::Label-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="card_name"
                      v-model="topUpOrdersAdd.adsId"
                    />
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="d-flex flex-column mb-7 fv-row">
                    <!--begin::Label-->
                    <label
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span> {{ this.$NOLIMITResource[this.dataUser.language].Note }}</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        :title="this.$NOLIMITResource[this.dataUser.language].TopUpAccount.TitleNoteTopup"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                    <!--end::Label-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="card_name"
                      v-model="topUpOrdersAdd.note"
                    />
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="d-flex flex-column mb-7 fv-row">
                    <!--begin::Label-->
                    <label
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span> {{ this.$NOLIMITResource[this.dataUser.language].Amount }}</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        :title="this.$NOLIMITResource[this.dataUser.language].TopUpAccount.AmountTopup"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                    <!--end::Label-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="card_name"
                      v-model="topUpOrdersAdd.amount"
                    />
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="d-flex flex-column mb-7 fv-row">
                    <!--begin::Label-->
                    <label
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span> {{ this.$NOLIMITResource[this.dataUser.language].TopupFee }}</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        :title="this.$NOLIMITResource[this.dataUser.language].TopupFee"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                    <!--end::Label-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="card_name"
                      v-model="topUpOrdersAdd.fee"
                    />
                  </div>
                  <!--end::Input group-->

                  <!--begin::Input group-->
                  <div class="d-flex flex-column mb-7 fv-row">
                    <!--begin::Label-->
                    <label
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span>{{ this.$NOLIMITResource[this.dataUser.language].CreatedDate }}</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        :title="this.$NOLIMITResource[this.dataUser.language].TopUpAccount.TitleCreatedTopup"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                    <!--end::Label-->
                    <div>
                      <v-date-picker class="inline-block h-full" v-model="topUpOrdersAdd.createDate" :timezone="timezone2">
                        <template v-slot="{ inputValue, togglePopover }">
                          <div class="flex items-center">
                            <input @click="togglePopover()" :value="inputValue" class="form-control form-control-solid flatpickr-input active" name="calendar_event_end_date" :placeholder="this.$NOLIMITResource[this.dataUser.language].CreatedDate" id="kt_calendar_datepicker_end_date" type="text" readonly="readonly" style="margin-bottom:20px">
                          </div>
                        </template>
                      </v-date-picker>
                    </div>
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="d-flex flex-column mb-7 fv-row">
                    <!--begin::Label-->
                    <label
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span>{{ this.$NOLIMITResource[this.dataUser.language].Currency }}</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        :title="this.$NOLIMITResource[this.dataUser.language].Currency"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                    <!--end::Label-->
                    <v-select id="styleSelect" v-model="topUpOrdersAdd.currency" :options="this.listCurrency" label="status"></v-select>
                  </div>
                  <!--end::Input group-->

                  <!--begin::Input group-->
                  <div class="d-flex flex-column mb-7 fv-row">
                    <!--begin::Label-->
                    <label
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span> {{ this.$NOLIMITResource[this.dataUser.language].Status }}</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        :title="this.$NOLIMITResource[this.dataUser.language].TopUpAccount.TitleStatusTopup"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                    <!--end::Label-->
                    <v-select id="styleSelect" v-model="topUpOrdersAdd.status" :options="this.listStatus" label="status"></v-select>
                  </div>
                  <!--end::Input group-->

                  <!--begin::Input group-->
                  <div class="d-flex flex-column mb-7 fv-row">
                    <!--begin::Label-->
                    <label
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span>{{ this.$NOLIMITResource[this.dataUser.language].LimitBeforeTopup }}</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        :title="this.$NOLIMITResource[this.dataUser.language].LimitBeforeTopup"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                    <!--end::Label-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="card_name"
                      v-model="topUpOrdersAdd.limitBefore"
                    />
                  </div>
                  <!--end::Input group-->


                   <!--begin::Input group-->
                   <div class="d-flex flex-column mb-7 fv-row">
                    <!--begin::Label-->
                    <label
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span>{{ this.$NOLIMITResource[this.dataUser.language].LimitAfterTopup }}</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        :title="this.$NOLIMITResource[this.dataUser.language].LimitAfterTopup"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                    <!--end::Label-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="card_name"
                      v-model="topUpOrdersAdd.limitAfter"
                    />
                  </div>
                  <!--end::Input group-->
                  <!--begin::Actions-->
                  <div class="text-center pt-15">
                    <button
                      @click="closeModalAddTopup"
                      type="reset"
                      id="kt_modal_new_card_cancel"
                      class="btn btn-light me-3"
                    >
                    {{ this.$NOLIMITResource[this.dataUser.language].Cancel }}
                    </button>
                    <button
                      type="submit"
                      id="kt_modal_new_card_submit"
                      class="btn btn-primary"
                    >
                      <span
                        class="indicator-label"
                        >{{ this.$NOLIMITResource[this.dataUser.language].Add }}</span
                      >
                      <span class="indicator-progress"
                        >Please wait...
                        <span
                          class="spinner-border spinner-border-sm align-middle ms-2"
                        ></span
                      ></span>
                    </button>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Modal body-->
            </div>
            <!--end::Modal content-->
          </div>
          <!--end::Modal dialog-->
        </div>
        <!--end::Modal - add topUpOrders-->

      <!--begin::Modal - Add Many Topup-->
      <div
          class="modal fade show"
          :class="{ block: isShowModalImportManyTopup }"
          id="kt_customers_export_modal"
          tabindex="-1"
          aria-hidden="true"
        >
          <!--begin::Modal dialog-->
          <div class="modal-dialog modal-dialog-centered mw-650px">
            <!--begin::Modal content-->
            <div class="modal-content">
              <!--begin::Modal header-->
              <div class="modal-header">
                <!--begin::Modal title-->
                <h2 class="fw-bold">{{ this.$NOLIMITResource[this.dataUser.language].TopUpAccount.AddBatchTopup }}</h2>
                <!--end::Modal title-->
                <!--begin::Close-->
                <div
                  id="kt_customers_export_close"
                  class="btn btn-icon btn-sm btn-active-icon-primary"
                >
                  <i class="ki-duotone ki-cross fs-1" @click="closeModalAddManyTopup">
                    <span class="path1"></span>
                    <span class="path2"></span>
                  </i>
                </div>
                <!--end::Close-->
              </div>
              <!--end::Modal header-->
              <!--begin::Modal body-->
              <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
                <!--begin::Form-->
                <form id="kt_customers_export_form" class="form" action="" @submit.prevent="convertStringToArray()">
                  
                  <!--begin::Input group-->
                  <div class="input-group input-group-solid " style="margin-bottom: 50px;">
                    <label
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span>{{ this.$NOLIMITResource[this.dataUser.language].TopUpAccount.AddBatchTopup }}</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        :title="this.$NOLIMITResource[this.dataUser.language].TopUpAccount.AddBatchTopup"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                      <textarea v-model="this.topupImport" class="form-control" style="width: 100%;" aria-label="With textarea"></textarea>
                  </div>
                  <!--end::Input group-->


                  <!--begin::Actions-->
                  <div class="text-center">
                    <button
                      type="reset"
                      id="kt_customers_export_cancel"
                      class="btn btn-light me-3"
                      @click="closeModalAddManyTopup"
                    >
                    {{ this.$NOLIMITResource[this.dataUser.language].Cancel }}
                    </button>
                    <button
                      type="submit"
                      id="kt_customers_export_submit"
                      class="btn btn-primary"
                    >
                      <span class="indicator-label">{{ this.$NOLIMITResource[this.dataUser.language].TopUpAccount.AddBatchTopup }}</span>
                      <span class="indicator-progress"
                        >Please wait...
                        <span
                          class="spinner-border spinner-border-sm align-middle ms-2"
                        ></span
                      ></span>
                    </button>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Modal body-->
            </div>
            <!--end::Modal content-->
          </div>
          <!--end::Modal dialog-->
        </div>
        <!--end::Modal - Add Many Topup-->

        <!--begin::Modal - Adjust Balance-->
        <div
          class="modal fade"
          id="kt_customers_export_modal"
          tabindex="-1"
          aria-hidden="true"
        >
          <!--begin::Modal dialog-->
          <div class="modal-dialog modal-dialog-centered mw-650px">
            <!--begin::Modal content-->
            <div class="modal-content">
              <!--begin::Modal header-->
              <div class="modal-header">
                <!--begin::Modal title-->
                <h2 class="fw-bold">Export Customers</h2>
                <!--end::Modal title-->
                <!--begin::Close-->
                <div
                  id="kt_customers_export_close"
                  class="btn btn-icon btn-sm btn-active-icon-primary"
                >
                  <i class="ki-duotone ki-cross fs-1">
                    <span class="path1"></span>
                    <span class="path2"></span>
                  </i>
                </div>
                <!--end::Close-->
              </div>
              <!--end::Modal header-->
              <!--begin::Modal body-->
              <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
                <!--begin::Form-->
                <form id="kt_customers_export_form" class="form" action="#">
                  <!--begin::Input group-->
                  <div class="fv-row mb-10">
                    <!--begin::Label-->
                    <label class="fs-5 fw-semibold form-label mb-5"
                      >Select Export Format:</label
                    >
                    <!--end::Label-->
                    <!--begin::Input-->
                    <select
                      data-control="select2"
                      data-placeholder="Select a format"
                      data-hide-search="true"
                      name="format"
                      class="form-select form-select-solid"
                    >
                      <option value="excell">Excel</option>
                      <option value="pdf">PDF</option>
                      <option value="cvs">CVS</option>
                      <option value="zip">ZIP</option>
                    </select>
                    <!--end::Input-->
                  </div>
                  <!--end::Input group-->
                  <!--begin::Input group-->
                  <div class="fv-row mb-10">
                    <!--begin::Label-->
                    <label class="fs-5 fw-semibold form-label mb-5"
                      >Select Date Range:</label
                    >
                    <!--end::Label-->
                    <!--begin::Input-->
                    <input
                      class="form-control form-control-solid"
                      placeholder="Pick a date"
                      name="date"
                    />
                    <!--end::Input-->
                  </div>
                  <!--end::Input group-->
                  <!--begin::Row-->
                  <div class="row fv-row mb-15">
                    <!--begin::Label-->
                    <label class="fs-5 fw-semibold form-label mb-5"
                      >Payment Type:</label
                    >
                    <!--end::Label-->
                    <!--begin::Radio group-->
                    <div class="d-flex flex-column">
                      <!--begin::Radio button-->
                      <label
                        class="form-check form-check-custom form-check-sm form-check-solid mb-3"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="1"
                          checked="checked"
                          name="payment_type"
                        />
                        <span class="form-check-label text-gray-600 fw-semibold"
                          >All</span
                        >
                      </label>
                      <!--end::Radio button-->
                      <!--begin::Radio button-->
                      <label
                        class="form-check form-check-custom form-check-sm form-check-solid mb-3"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="2"
                          checked="checked"
                          name="payment_type"
                        />
                        <span class="form-check-label text-gray-600 fw-semibold"
                          >Visa</span
                        >
                      </label>
                      <!--end::Radio button-->
                      <!--begin::Radio button-->
                      <label
                        class="form-check form-check-custom form-check-sm form-check-solid mb-3"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="3"
                          name="payment_type"
                        />
                        <span class="form-check-label text-gray-600 fw-semibold"
                          >Mastercard</span
                        >
                      </label>
                      <!--end::Radio button-->
                      <!--begin::Radio button-->
                      <label
                        class="form-check form-check-custom form-check-sm form-check-solid"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="4"
                          name="payment_type"
                        />
                        <span class="form-check-label text-gray-600 fw-semibold"
                          >American Express</span
                        >
                      </label>
                      <!--end::Radio button-->
                    </div>
                    <!--end::Input group-->
                  </div>
                  <!--end::Row-->
                  <!--begin::Actions-->
                  <div class="text-center">
                    <button
                      type="reset"
                      id="kt_customers_export_cancel"
                      class="btn btn-light me-3"
                    >
                      Discard
                    </button>
                    <button
                      type="submit"
                      id="kt_customers_export_submit"
                      class="btn btn-primary"
                    >
                      <span class="indicator-label">Submit</span>
                      <span class="indicator-progress"
                        >Please wait...
                        <span
                          class="spinner-border spinner-border-sm align-middle ms-2"
                        ></span
                      ></span>
                    </button>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Modal body-->
            </div>
            <!--end::Modal content-->
          </div>
          <!--end::Modal dialog-->
        </div>
        <!--end::Modal - New Card-->
        <!--end::Modals-->
      </div>
      <!--end::Content container-->
    </div>
    <!--end::Content-->
  </div>
</template>
<style scoped>
.table thead th:last-child{
  height: 70px;
}
.active__update__combobox {
  z-index: 5 !important;
}
.menu {
  left: -20px;
  top: 53px;
  position: relative;
}
td {
  text-align: start;
}
.menu-sub-dropdown {
  position: absolute;
}
.block {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}
.loading-1 {
  height: 500px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 50px;
}
.show-dropfilter{
  z-index: 107; position: absolute; inset: 0px 0px auto auto; margin: 0px;
  top: 90px;
  right: 20px;
  text-align: start;
}
#styleSelect{
  background: #f9f9f9 !important;
  border:none !important;
  border-radius: 5px;
}
</style>
<script>
import { mapActions ,mapMutations,mapGetters} from "vuex";
import router from '@/router/router.js';
export default {
  name: "TopUpList",
  data() {
    return {
      dataUser:{language:"VN"},
      timezones: [
          'Pacific/Midway',      // -11
          'Pacific/Niue',        // -11
          'Pacific/Honolulu',    // -10
          'Pacific/Pago_Pago',   // -11
          'America/Anchorage',   // -9
          'America/Los_Angeles', // -8
          'America/Tijuana',     // -8
          'America/Denver',      // -7
          'America/Phoenix',     // -7
          'America/Chicago',      // -6
          'America/Mexico_City', // -6
          'America/Regina',      // -6
          'America/New_York',    // -5
          'America/Caracas',     // -4.5
          'America/Halifax',     // -4
          'America/Argentina/Buenos_Aires', // -3
          'America/Sao_Paulo',   // -3
          'Atlantic/Azores',     // -1
          'Atlantic/Cape_Verde', // -1
          'UTC',                 // 0
          'Europe/London',       // +0
          'Europe/Paris',        // +1
          'Europe/Istanbul',     // +3
          'Europe/Moscow',       // +3
          'Asia/Dubai',          // +4
          'Asia/Yekaterinburg',  // +5
          'Asia/Almaty',         // +6
          'Asia/Colombo',        // +6
          'Asia/Bangkok',        // +7
          'Asia/Ho_Chi_Minh',    // +7
          'Asia/Jakarta',        // +7
          'Asia/Taipei',         // +8
          'Asia/Singapore',      // +8
          'Asia/Kuala_Lumpur',   // +8
          'Asia/Hong_Kong',      // +8
          'Asia/Ulaanbaatar',    // +8
          'Asia/Pyongyang',      // +9
          'Asia/Tokyo',          // +9
          'Australia/Darwin',    // +9.5
          'Australia/Adelaide',  // +10
          'Australia/Sydney',    // +10
          'Pacific/Guam',        // +10
          'Australia/Brisbane',  // +10
          'Asia/Vladivostok',    // +10
          'Pacific/Fiji',        // +12
          'Pacific/Tongatapu',   // +13
          'Pacific/Apia',        // +13
        ],
      listStatus:['Thành công','Thất bại'],
      listCurrency:['VND','USD'],
      topUpOrder:{},
      idUser:0,
      onLoading: true,
      topUpOrders: [],
      onShowMenu: false,
      currenOnshowId: 0,
      topUpOrdersCurrent: {},
      topUpOrdersAdd:{},
      isShowModalAddAds: false,
      currentBalanceUser: 0,
      currentTotalMoneyUser: 0,
      isShowModalUpdateAds: false,
      changeMoney: 0,
      formMode: "",
      pageSize: 10,
      pageNumber: 1,
      totalPage: 0,
      firstPage: 0,
      inputValue: "",
      isAdmin:true,
      hasData:true,
      numberPerPage:[10,25,50,100],
      status:"",
      dateStart: null,
      dateEnd: null,
      timezoneIndex2: 17,
      timezoneIndex: 20,
      timezoneIndex1: 1,
      totalStatusFailed:0,
      totalStatusProcessing:0,
      totalStatusSuccess:0,
      isResizing: false,
      initialX: 0,
      initialWidth: 0,
      resizingCol: null,
      columns: [
             { label: 'Id topup', width: 250, property: "TopupOrderId" },
            { label: 'FacebookAdsId', width: 300, property: "FacebookAdsId" },
            { label: this.$NOLIMITResource["VN"].Note, width: 300, property: "Note" },
            { label: 'UserId', width: 300, property: "UserId" },
            { label: 'UserName', width: 300, property: "UserName" },
            { label: this.$NOLIMITResource["VN"].Amount, width: 300, property: "Amount" },
            { label: this.$NOLIMITResource["VN"].TopupFee, width: 300, property: "Fee" },
            { label: this.$NOLIMITResource["VN"].PaymentAmount, width: 300, property: "AmountDisplay" },
            { label: this.$NOLIMITResource["VN"].Status, width: 300, property: "Status" },
            { label: this.$NOLIMITResource["VN"].LimitBeforeTopup, width: 300, property: "LimitBefore" },
            { label:  this.$NOLIMITResource["VN"].LimitAfterTopup, width: 300, property: "LimitAfter" },
            { label: this.$NOLIMITResource["VN"].CreatedDate, width: 300, property: "CreateDate" },
      ],
      currentIndex: null,
      currentProperty: "",
      modeSort:"",
      sortingAsc: null,
      sortingNumber: null, 
      isShowModalAddTopup:false,
      isShowModalUpdateTopup:false,
      currentUpdateTopupId:0,
      topupCurrent:{},
      isShowModalImportManyTopup:false,
      topupImport:'',
    };
  },
  watch: {
    dataUser:{
      handler: function() {
        this.columns = [
             { label: 'Id topup', width: 250, property: "TopupOrderId" },
            { label: 'FacebookAdsId', width: 300, property: "FacebookAdsId" },
            { label: this.$NOLIMITResource[this.dataUser.language].Note, width: 300, property: "Note" },
            { label: 'UserId', width: 300, property: "UserId" },
            { label: 'UserName', width: 300, property: "UserName" },
            { label: this.$NOLIMITResource[this.dataUser.language].Amount, width: 300, property: "Amount" },
            { label: this.$NOLIMITResource[this.dataUser.language].TopupFee, width: 300, property: "Fee" },
            { label: this.$NOLIMITResource[this.dataUser.language].PaymentAmount, width: 300, property: "AmountDisplay" },
            { label: this.$NOLIMITResource[this.dataUser.language].Status, width: 300, property: "Status" },
            { label: this.$NOLIMITResource[this.dataUser.language].LimitBeforeTopup, width: 300, property: "LimitBefore" },
            { label:  this.$NOLIMITResource[this.dataUser.language].LimitAfterTopup, width: 300, property: "LimitAfter" },
            { label: this.$NOLIMITResource[this.dataUser.language].CreatedDate, width: 300, property: "CreateDate" },
      ]
      }
    },
    pageSize() {
      this.reDisplay();
    },
      'topUpOrder.amount': function(newAmount) {
      const amountNumber = parseFloat(newAmount);
      // Tính toán fee dựa trên 3% của newAmount
      this.topUpOrder.fee = (newAmount * 0.03).toFixed(2); // Làm tròn đến 2 chữ số thập phân
      this.topUpOrder.amountDisplay = (amountNumber + parseFloat(this.topUpOrder.fee)).toString() // Làm tròn đến 2 chữ số thập phân
      // Nếu bạn muốn fee là số nguyên, bạn có thể sử dụng:
      // this.topUpOrder.fee = Math.round(newAmount * 0.03);
      },
      
  },
  computed:{
    ...mapGetters("listModule", [
      "onShowMenuFilter",
      "formatCurrency",
      "formatDateTime",
    ]),
    timezone2() {
      return this.timezones[this.timezoneIndex];
    },
    timezone1() {
      return this.timezones[this.timezoneIndex1];
    },
    timezone3() {
      return this.timezones[this.timezoneIndex2];
    },
    attributes() {
      return[ {
        highlight: true,
        dates: this.dateStart,
      }];
    },
  },
 async created() {

  await this.$apiService
  .getUserProfile().then((response) => {
    if(response.data.data.language == "VN" || response.data.data.language == "EN"){
          this.dataUser = response.data.data;
      }else{
          this.dataUser = response.data.data;
          this.dataUser.language = "VN";
      }
      this.idUser = response.data.data.userId
      if(response.data.data.isAdmin == false && response.data.data.isInternalAccountant != true){
                this.isAdmin=false
                router.push({ name: 'ErrorNotFound' });
            }
      this.$apiService
          .getAllTopUpOrderByAdmin(this.pageNumber, this.pageSize)
          .then((res) => {
          setTimeout(() => {
              this.onLoading = false;
          }, 1000);
          this.topUpOrders = res.data.data;
          if(res.data.data.length == 0){
                this.hasData = false;
              }
             
          this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
          console.error("Lỗi khi lấy dữ liệu:", error);
          });

          this.$apiService.getAllTopUpOrderNoPagingByAdmin().then((res)=>{
            for(let i=0;i<res.data.data.length;i++){
                  if(res.data.data[i].status == "Thành công"){
                    this.totalStatusSuccess +=1;
                  }else if(res.data.data[i].status == "Đang xử lý"){
                    this.totalStatusProcessing +=1;
                  }else if(res.data.data[i].status == "Thất bại"){
                    this.totalStatusFailed +=1;
                  }
              }
          })
          })
  .catch((error) => {
        console.error("Lỗi khi lấy dữ liệu:", error);
      });
  },

  methods: {

    ...mapActions("listModule", ["toggleMenuFilter","swalSuccess","swalError","swalWarning"]),
    ...mapMutations("listModule", ["closeMenuFilter"]),
    openModalAddAds() {
      this.isShowModalAddAds = true;
    },
    closeModalAddAds() {
      this.isShowModalAddAds = false;
      this.onShowMenu = false;
    },

    openModalTopUpAds(id) {
      this.isShowModalUpdateAds = true;
      this.$apiService
        .getAdsById(id)
        .then((res) => {
          this.topUpOrdersCurrent = res.data.data;
        })
        .catch((error) => {
          console.error("Lỗi khi lấy dữ liệu:", error);
        });
    },
    closeModalUpdateAds() {
      this.isShowModalUpdateAds = false;
      this.onShowMenu = false;
    },
    addTopUpOrder() {
      this.isShowModalUpdateAds = false;
      this.topUpOrder.topUpOrdersId = this.currenOnshowId;
      console.log(this.topUpOrder)
      this.$apiService.addTopUpOrder(this.topUpOrder)
        .then(() => {
          this.onShowMenu = false;
          this.onEnterSearch();
          this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].TopUpAccount.SuccessAddTopup);

        })
        .catch((error) => {
          this.swalError({ message:error.response.data.message, language: this.dataUser.language})
        });
    },
    toggleMenuItem(id) {
      this.onShowMenu = !this.onShowMenu;
      this.currenOnshowId = id;
    },
    addTopup() {
      this.isShowModalAddTopup = false;
      this.$apiService
        .addTopUpOrderCustom(this.topUpOrdersAdd)
        .then(() => {
          this.onShowMenu = false;
          this.onEnterSearch();
          this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].TopUpAccount.SuccessAddTopup);
        })
        .catch((error) => {
          this.swalError({ message:error.response.data.message, language: this.dataUser.language})
        });
    },
    selectPage(index) {
      this.pageNumber = index;
      if (this.totalPage >= 4) {
        if (index == this.totalPage) {
          this.firstPage = index - 4;
        } else if (index >= 3) {
          this.firstPage = index - 3;
        } else {
          this.firstPage = 0;
        }
      }
      if (this.status != "" || this.dateStart != null || this.dateEnd != null) {
          this.onFilterSearch();
        } else if (this.sortingNumber != null || this.sortingAsc != null) {
          this.onSorting(this.currentIndex, this.currentProperty, "selectPage");
        } else {
          this.onEnterSearch();
        }
    },
    reDisplay() {
      this.hasData = true;
      this.$apiService
      .getAllTopUpOrderByAdmin(this.pageNumber, this.pageSize)
      .then((res) => {
        this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.topUpOrders = res.data.data;
          if(res.data.data.length == 0){
                this.hasData = false;
              }
          this.totalPage = res.data.pageData.pageCount;
      })
      .catch((error) => {
        console.error("Lỗi khi lấy dữ liệu:", error);
      });
    },
    /**
     * Chọn next trang tiếp theo trong mục phân trang
     * Author: ndanh (20/09/2023)
     */
    nextPage() {
      if (this.pageNumber != this.totalPage) {
        this.pageNumber += 1;
        if (this.totalPage >= 4) {
          if (this.pageNumber == this.totalPage) {
            this.firstPage = this.pageNumber - 4;
          } else if (this.pageNumber >= 3) {
            this.firstPage = this.pageNumber - 3;
          } else {
            this.firstPage = 0;
          }
        }
      }
      if (this.status != "" || this.dateStart != null || this.dateEnd != null) {
          this.onFilterSearch();
        } else if (this.sortingNumber != null || this.sortingAsc != null) {
          this.onSorting(this.currentIndex, this.currentProperty, "selectPage");
        } else {
          this.onEnterSearch();
        }
    },
    /**
     * Chọn prev trang trước đó trong mục phân trang
     * Author: ndanh (20/09/2023)
     */
    prevPage() {
      if (this.pageNumber != 1) {
        this.pageNumber -= 1;
        if (this.totalPage >= 4) {
          if (this.pageNumber == this.totalPage) {
            this.firstPage = this.pageNumber - 4;
          } else if (this.pageNumber >= 3) {
            this.firstPage = this.pageNumber - 3;
          } else {
            this.firstPage = 0;
          }
        }
      }
      if (this.status != "" || this.dateStart != null || this.dateEnd != null) {
          this.onFilterSearch();
        } else if (this.sortingNumber != null || this.sortingAsc != null) {
          this.onSorting(this.currentIndex, this.currentProperty, "selectPage");
        } else {
          this.onEnterSearch();
        }
    },
    onEnterSearch() {
      this.hasData = true;


      if (this.inputValue == "") {
        this.reDisplay();
      } else {    
        this.$apiService
          .getAllTopUpOrderByAdminFilterStatus(
            this.pageNumber,
            this.pageSize,
            this.status,
            this.inputValue,
            this.currentProperty,
            this.modeSort,
          )
          .then((res) => {
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.topUpOrders = res.data.data;
            if(res.data.data.length == 0){
                this.hasData = false;
              }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      }
    },

    onEnterSearchFirst() {
      this.hasData = true;
      if (this.inputValue == "") {
        this.reDisplay();
      } else {
        this.pageNumber = 1;
        this.firstPage = 0;
        this.$apiService
          .getAllTopUpOrderByAdminFilterStatus(
            this.pageNumber,
            this.pageSize,
            this.status,
            this.inputValue,
            this.currentProperty,
            this.modeSort,
          )
          .then((res) => {
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.topUpOrders = res.data.data;
            if(res.data.data.length == 0){
                this.hasData = false;
              }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      }
    },
    closeMenuItem(){
      this.onShowMenu = false;
    },
    choosePageSize(event) {
      this.pageSize = event.target.value;
      this.reDisplay();
    },


    onFilterSearch(){
        this.hasData = true;
        if((this.dateStart == null || this.dateEnd == null || this.dateEnd < this.dateStart) && this.status == "" && this.inputValue == ""){
          this.reDisplay();
        }else if(this.dateStart == null || this.dateEnd == null || this.dateEnd < this.dateStart){
        
          this.$apiService.getAllTopUpOrderByAdminFilterStatus(
            this.pageNumber,
            this.pageSize,
            this.status,
            this.inputValue,
            this.currentProperty,
            this.modeSort,
          ).then((res)=>{
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.topUpOrders = res.data.data;
            if(res.data.data.length == 0){
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
        }else if(this.status == ""){
          const dateEndConvert = this.convertToEndOfDay(this.dateEnd)
          console.log("date end"+dateEndConvert)
          this.$apiService.getAllTopUpOrderByAdminFilter(
            this.pageNumber,
            this.pageSize,
            "",
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,
            this.currentProperty,
            this.modeSort,
          ).then((res)=>{
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.topUpOrders = res.data.data;
            if(res.data.data.length == 0){
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
        }else if(this.inputValue == ""){
          const dateEndConvert = this.convertToEndOfDay(this.dateEnd)
          console.log("date end"+dateEndConvert)
          this.$apiService.getAllTopUpOrderByAdminFilter(
            this.pageNumber,
            this.pageSize,
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            "",
            this.currentProperty,
            this.modeSort,
          ).then((res)=>{
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.topUpOrders = res.data.data;
            if(res.data.data.length == 0){
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
        }else{
          const dateEndConvert = this.convertToEndOfDay(this.dateEnd)
          console.log("date end"+dateEndConvert)
          this.$apiService.getAllTopUpOrderByAdminFilter(
            this.pageNumber,
            this.pageSize,
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,
            this.currentProperty,
            this.modeSort,
          ).then((res)=>{
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.topUpOrders = res.data.data;
            if(res.data.data.length == 0){
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
        }
        this.closeMenuFilter();
      },
      onFilterSearchFirst(){
        this.hasData = true;
        if((this.dateStart == null || this.dateEnd == null || this.dateEnd < this.dateStart) && this.status == "" && this.inputValue == ""){
          this.reDisplay();
        }else{ 
        this.pageNumber = 1;
        this.firstPage = 0;
          if(this.dateStart == null || this.dateEnd == null || this.dateEnd < this.dateStart){
          this.$apiService.getAllTopUpOrderByAdminFilterStatus(
            this.pageNumber,
            this.pageSize,
            this.status,
            this.inputValue,
            this.currentProperty,
            this.modeSort,
          ).then((res)=>{
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.topUpOrders = res.data.data;
            if(res.data.data.length == 0){
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
        }else if(this.status == ""){
          const dateEndConvert = this.convertToEndOfDay(this.dateEnd)
          console.log("date end"+dateEndConvert)
          this.$apiService.getAllTopUpOrderByAdminFilter(
            this.pageNumber,
            this.pageSize,
            "",
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,
            this.currentProperty,
            this.modeSort,
          ).then((res)=>{
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.topUpOrders = res.data.data;
            if(res.data.data.length == 0){
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
        }else  if(this.inputValue == ""){
          const dateEndConvert = this.convertToEndOfDay(this.dateEnd)
          console.log("date end"+dateEndConvert)
          this.$apiService.getAllTopUpOrderByAdminFilter(
            this.pageNumber,
            this.pageSize,
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            "",
            this.currentProperty,
            this.modeSort,
          ).then((res)=>{
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.topUpOrders = res.data.data;
            if(res.data.data.length == 0){
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
        }else{
          const dateEndConvert = this.convertToEndOfDay(this.dateEnd)
          console.log("date end"+dateEndConvert)
          this.$apiService.getAllTopUpOrderByAdminFilter(
            this.pageNumber,
            this.pageSize,
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,
            this.currentProperty,
            this.modeSort,
          ).then((res)=>{
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.topUpOrders = res.data.data;
            if(res.data.data.length == 0){
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
        }
      }
        this.closeMenuFilter();
      },
      convertToEndOfDay(date) {
      date.setHours(23, 59, 59, 999);

      // Lấy các thành phần ngày giờ
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
      const milliseconds = date.getMilliseconds().toString().padStart(3, '0');

      // Tạo chuỗi ISO
      const isoString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;

      return isoString;
      },
      onResetFilter(){
        this.status="";
        this.dateStart = null;
        this.dateEnd = null;
      },
      startResize(event, index) {
        if (event.offsetX > event.target.offsetWidth - 10) {
                this.isResizing = true;
                this.initialX = event.clientX;
                this.initialWidth = this.columns[index].width;
                this.resizingCol = index;

                document.addEventListener('mousemove', this.handleMouseMove);
                document.addEventListener('mouseup', this.handleMouseUp);
        } 
      },
      handleMouseMove(event) {
          if (this.isResizing && this.resizingCol !== null) {
              const delta = event.clientX - this.initialX;
              const newWidth = Math.max(50, this.initialWidth + delta);
              this.columns[this.resizingCol] = { ...this.columns[this.resizingCol], width: newWidth };
          }
      },
      handleMouseUp() {
          this.isResizing = false;
          this.initialX = 0;
          this.initialWidth = 0;
          this.resizingCol = null;

          document.removeEventListener('mousemove', this.handleMouseMove);
          document.removeEventListener('mouseup', this.handleMouseUp);
      },
      onSorting(index, property, mode) {
      this.currentIndex = index;
      this.currentProperty = property;
      if(this.dateStart == null || this.dateEnd == null || this.dateEnd < this.dateStart){

      if (this.sortingNumber == index && mode == "changeSort") {
        this.sortingNumber = null;
        this.sortingAsc = index;
        this.$apiService
          .getAllTopUpOrderByAdminFilterStatus(
            this.pageNumber,
            this.pageSize,
            this.status,
            this.inputValue,
            property,
            "ASC"
          )
          .then((res) => {
            this.modeSort = "ASC";
            console.log(res.data.data);
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.topUpOrders = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      } else if (this.sortingNumber == index && mode == "selectPage") {
        this.sortingNumber = null;
        this.sortingAsc = null;
        this.sortingNumber = index;
        this.$apiService
          .getAllTopUpOrderByAdminFilterStatus(
            this.pageNumber,
            this.pageSize,
            this.status,
            this.inputValue,
            property,
            "DESC"
          )
          .then((res) => {
            this.modeSort = "DESC";
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.topUpOrders = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      } else if (this.sortingAsc == index && mode == "changeSort") {
        this.sortingAsc = null;
        this.sortingNumber = index;
        this.$apiService
          .getAllTopUpOrderByAdminFilterStatus(
            this.pageNumber,
            this.pageSize,
            this.status,
            this.inputValue,
            property,
            "DESC"
          )
          .then((res) => {
            this.modeSort = "DESC";
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.topUpOrders = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      } else if (this.sortingAsc == index && mode == "selectPage") {
        this.sortingAsc = null;
        this.sortingNumber = null;
        this.sortingAsc = index;
        this.$apiService
          .getAllTopUpOrderByAdminFilterStatus(
            this.pageNumber,
            this.pageSize,
            this.status,
            this.inputValue,
            property,
            "ASC"
          )
          .then((res) => {
            this.modeSort = "ASC";
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.topUpOrders = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      } else {
        this.sortingNumber = null;
        this.sortingAsc = null;
        this.sortingNumber = index;
        this.$apiService
          .getAllTopUpOrderByAdminFilterStatus(
            this.pageNumber,
            this.pageSize,
            this.status,
            this.inputValue,
            property,
            "DESC"
          )
          .then((res) => {
            this.modeSort = "DESC";
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.topUpOrders = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      }
    }else{
      const dateEndConvert = this.convertToEndOfDay(this.dateEnd)
        if (this.sortingNumber == index && mode == "changeSort") {
        this.sortingNumber = null;
        this.sortingAsc = index;
        this.$apiService
          .getAllTopUpOrderByAdminFilter(
            this.pageNumber,
            this.pageSize,
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,
            property,
            "ASC"
          )
          .then((res) => {
            this.modeSort = "ASC";
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.topUpOrders = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      } else if (this.sortingNumber == index && mode == "selectPage") {
        this.sortingNumber = null;
        this.sortingAsc = null;
        this.sortingNumber = index;
        this.$apiService
          .getAllTopUpOrderByAdminFilter(
            this.pageNumber,
            this.pageSize,
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,
            property,
            "DESC"
          )
          .then((res) => {
            this.modeSort = "DESC";
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.topUpOrders = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      } else if (this.sortingAsc == index && mode == "changeSort") {
        this.sortingAsc = null;
        this.sortingNumber = index;
        this.$apiService
          .getAllTopUpOrderByAdminFilter(
            this.pageNumber,
            this.pageSize,
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,
            property,
            "DESC"
          )
          .then((res) => {
            this.modeSort = "DESC";
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.topUpOrders = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      } else if (this.sortingAsc == index && mode == "selectPage") {
        this.sortingAsc = null;
        this.sortingNumber = null;
        this.sortingAsc = index;
        this.$apiService
          .getAllTopUpOrderByAdminFilter(
            this.pageNumber,
            this.pageSize,
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,
            property,
            "ASC"
          )
          .then((res) => {
            this.modeSort = "ASC";
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.topUpOrders = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      } else {
        this.sortingNumber = null;
        this.sortingAsc = null;
        this.sortingNumber = index;
        this.$apiService
          .getAllTopUpOrderByAdminFilter(
            this.pageNumber,
            this.pageSize,
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,
            property,
            "DESC"
          )
          .then((res) => {
            this.modeSort = "DESC";
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.topUpOrders = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      }
      }
    },
    formatCurrencyValue(value) {
      // Chuyển đổi số thành chuỗi
      let stringValue = value.toString();

      // Chia chuỗi thành mảng các nhóm mỗi 3 chữ số
      let parts = stringValue.split('.');
      let mainPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

      // Nếu có phần thập phân, thêm vào chuỗi
      let result = parts.length > 1 ? mainPart + ',' + parts[1] : mainPart;

      return result;
    },
    openModalAddTopup() {
      this.errorsTopup={
        adsId:"",
        note:"",
        amount:"",
        fee:"",
        createdDate:"",
        currency:"",
        status:"",
        limitBefore:"",
        limitAfter:"",
      }
      this.isShowModalAddTopup = true;
    },
    closeModalAddTopup() {
      this.isShowModalAddTopup = false;
    },

    openModalUpdateTopUp(id){
      this.currentUpdateTopupId = id;
      this.isShowModalUpdateTopup = true;
      this.$apiService.getTopUpOrderById(id).then((res)=>{
        this.topupCurrent = res.data.data;
        
      })
    },
    closeModalUpdateTopUp(){
      this.isShowModalUpdateTopup = false;
    },
    updateTopUp(){
        this.isShowModalUpdateTopup = false;
        this.$apiService.updateTopUpOrder(this.topupCurrent,this.currentUpdateTopupId).then(()=>{
            this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].TopUpAccount.SuccessUpdateTopup);
            this.onEnterSearch();
        }).catch((error)=>{
          this.swalError( { message:error.response.data.message, language: this.dataUser.language})
        })
    },
    openModalAddManyTopup(){
      this.isShowModalImportManyTopup = true;
    },
    closeModalAddManyTopup(){
      this.isShowModalImportManyTopup = false;
    },
    convertStringToArray() {
      const formData = new FormData();
        formData.append('topupData', this.topupImport);
        this.$apiService.importManyTopup(formData)
        .then(()=>{
          this.reDisplay()
          this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].TopUpAccount.SuccessAddManyTopup)
        this.closeModalAddManyTopup()
        }).catch((error)=>{
          this.swalError( { message:error.response.data.message, language: this.dataUser.language})
        })
    },
    async confirmReturnDeleteTopup(id){
      const result = await this.swalWarning({ message:this.$NOLIMITResource[this.dataUser.language].TopUpAccount.WarningDeleteTopup, language: this.dataUser.language});
      if (result.isConfirmed) {
        this.deleteTopup(id)
      } else {
        // Thực hiện công việc khi người dùng hủy bỏ
      }
    },
    deleteTopup(id){
      this.$apiService.deleteTopUpOrder(id).then(()=>{
        this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].TopUpAccount.SuccessDeleteTopup+id);
        this.onEnterSearch();
      }).catch((error)=>{
          this.swalError({ message:error.response.data.message, language: this.dataUser.language})
        })
    },

    exportCSV(){
      let data = [];
      for (let i = 0; i < this.topUpOrders.length; i++){
        let status;
        switch (this.topUpOrders[i].status) {
            case 'Thất bại':
            status = this.$NOLIMITResource[this.dataUser.language].Failed;
                break;
            case 'Thành công':
            status = this.$NOLIMITResource[this.dataUser.language].Success;
                break;
            case 'Đang xử lý':
            status = this.$NOLIMITResource[this.dataUser.language].Processing;
                break;    
            default:
            status = this.$NOLIMITResource[this.dataUser.language].NotDetermined;
              break;
        }
        let item = {};
        if(this.dataUser.language == "EN"){
           item = {
            'ID topup':this.topUpOrders[i].topupOrderId,
            'FACEBOOKADSID': "' "+this.topUpOrders[i].facebookAdsId,
            'Note' : this.topUpOrders[i].note,
            'UserID': this.topUpOrders[i].userId,
            'Username': this.topUpOrders[i].userName,
            'Amount': this.topUpOrders[i].amount,
            'Topup Fee' : this.topUpOrders[i].fee,
            'Payment Amount' : this.topUpOrders[i].amountDisplay,
            'Status' : status,
            'Limit Before Topup' : this.topUpOrders[i].limitBefore,
            'Limit After Topup' : this.topUpOrders[i].limitAfter,
            'Created Date' : this.formatDateTime(this.topUpOrders[i].createDate),
          }
        }else{
           item = {
            'ID TOPUP':this.topUpOrders[i].topupOrderId,
            'FACEBOOKADSID': "' "+this.topUpOrders[i].facebookAdsId,
            'GHI CHÚ' : this.topUpOrders[i].note,
            'USERID': this.topUpOrders[i].userId,
            'USERNAME': this.topUpOrders[i].userName,
            'SỐ TIỀN': this.topUpOrders[i].amount,
            'PHÍ TOPUP' : this.topUpOrders[i].fee,
            'TỔNG TIỀN THANH TOÁN' : this.topUpOrders[i].amountDisplay,
            'TRẠNG THÁI' : status,
            'GIỚI HẠN TRƯỚC TOPUP' : this.topUpOrders[i].limitBefore,
            'GIỚI HẠN SAU TOPUP' : this.topUpOrders[i].limitAfter,
            'NGÀY TẠO' : this.formatDateTime(this.topUpOrders[i].createDate),
          }
        }
        

          data.push(item);
      }
      const csvContent1 = this.convertToCSV(data);
      var BOM = "\uFEFF";
      var csvContent = BOM + csvContent1;
      const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8'});
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download','export_topup.csv');
      link.click();
    },
    convertToCSV(data){
        const headers = Object.keys(data[0]);
        const rows = data.map(obj => headers.map(header => obj[header]));
        const headerRow = headers.join(',');
        const csvRows = [headerRow, ...rows.map(row=> row.join(','))];
        return csvRows.join('\n');
    }
  },
};
</script>
