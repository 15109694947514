<template>
  <div v-if="this.isAdmin == false"> {{ this.$NOLIMITResource[this.dataUser.language].PermissionPage }}</div> 
    <!--begin::Content wrapper-->
    <div v-else class="d-flex flex-column flex-column-fluid">
      <!--begin::Toolbar-->
      <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6" style="padding-top: 0px !important;padding-bottom: 10px !important;">
        <!--begin::Toolbar container-->
        <div
          id="kt_app_toolbar_container"
          class="app-container d-flex flex-stack"
        >
          <!--begin::Page title-->
          <div
            class="page-title d-flex flex-column justify-content-center flex-wrap me-3"
          >
            <!--begin::Title-->
            <h1
              class="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0"
            >
            {{ this.$NOLIMITResource[this.dataUser.language].RefundHistory.TitleAll }}
            </h1>
            <!--end::Title-->
          </div>
          <!--end::Page title-->
        </div>
        <!--end::Toolbar container-->
      </div>
      <!--end::Toolbar-->
      <!--begin::Content-->
      <div id="kt_app_content" class="app-content flex-column-fluid">
        <!--begin::Content container-->
        <div id="kt_app_content_container" class="app-container">
          <!--begin::Card-->
          <div class="card">
            <!--begin::Card header-->
          <div class="card-header border-0 pt-6" style="align-items: center;padding-top: 0px !important;">
            <!--begin::Card title-->
            <div class="card-title">
              <!--begin::Search-->
              <div class="d-flex align-items-center position-relative my-1">
                <i class="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                  <span @click="onEnterSearchFirst" class="path1" style="cursor:pointer;z-index: 2;"></span>
                  <span class="path2"></span>
                </i>
                <input
                  v-on:keyup.enter="onEnterSearchFirst"
                  v-model="inputValue"
                  type="text"
                  class="form-control form-control-solid w-350px ps-12"
                  :placeholder="this.$NOLIMITResource[this.dataUser.language].RefundHistory.TextSearchAll"
                />
              </div>
              <!--end::Search-->
            </div>  
           
          </div>
          <!--end::Card header-->
            <!--begin::Card body-->
            <div class="card-body pt-0" style="margin-top: 20px;">
              <!--begin::Table-->
              <table
                class="table align-middle table-row-dashed fs-6 gy-5"
                id="kt_customers_table"
              >
                <thead>
                  <tr
                    class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0"
                  >
                  <template v-for="(column, index) in columns" :key="index" >
                      <th class="min-w-125px" style="vertical-align: baseline;" :class="{
                        sorting_desc: this.sortingNumber == index,
                        sorting_asc: this.sortingAsc == index,
                      }" :style="{ 'width': column.width + 'px !important'}">
                          <div @mousedown="startResize($event, index)"><span
                            @click="
                              onSorting(index, column.property, 'changeSort')
                            "
                            >{{ column.label }}</span
                          ></div>
                      </th>
                    </template>
                    <th class="text-end min-w-70px"> {{ this.$NOLIMITResource[this.dataUser.language].Feature }}</th>
                 
                  </tr>
                </thead>
                <div class="loading-1" v-if="onLoading" >
                  <span class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </span>
                </div>
                <div style="margin-top: 20px;width: 100%;" v-if="!this.onLoading && this.hasData == false">{{ this.$NOLIMITResource[this.dataUser.language].EmptyData }}</div> 
                <tbody v-if="!this.onLoading && this.hasData == true" class="fw-semibold text-gray-600">
                  <tr v-for="item in withDraw" :key="item.withDrawId">
                    <td>{{ item.userId    }}</td>
                    <td>{{ item.facebookAdsId    }}</td>
                    <td>
                    <a class="text-gray-600 text-hover-primary mb-1">
                        <div
                        v-if="item.status == 'Hoàn thành'"
                          :class="`badge badge-light-success`"
                        >
                        {{ this.$NOLIMITResource[this.dataUser.language].RefundHistory.StatusRefundComplete }}
                        </div>
                      </a>
                    <a class="text-gray-600 text-hover-primary mb-1">
                      <div
                      v-if="item.status == 'Đã hoàn tiền 90%'"
                        :class="`badge badge-light-warning`"
                      >
                      {{ this.$NOLIMITResource[this.dataUser.language].RefundHistory.StatusRefund90 }}
                      </div>
                    </a>
                    <a class="text-gray-600 text-hover-primary mb-1">
                      <div
                      v-if="item.status == 'Hoàn tiền thất bại'"
                        :class="`badge badge-light-danger`"
                      >
                      {{ this.$NOLIMITResource[this.dataUser.language].RefundHistory.StatusRefundFailed }}
                      </div>
                    </a>
                  </td>
                    <td>{{ formatDateTime(item.createdDate) }}</td>
                    <td>{{ formatDateTime(item.updateDate) }}</td>
                    <td>{{ formatCurrency(item.spent) }}</td>
                    <td>{{ formatCurrency(item.spentLimit) }}</td>
                    <td>{{ formatCurrency(item.remainingAmount) }}</td>
                    <td>{{ formatCurrency(item.currentRefundAmount) }}</td>
                    <td>{{ formatCurrency(item.reconciliationRefundAmount) }}</td>
                    <td>{{ formatCurrency(item.totalRefundAmount) }}</td>
                    <td
                    class="text-end"
                    :class="{
                      active__update__combobox:
                        onShowMenu && currenOnshowId === item.withdrawId,
                    }" style="min-width:88px"
                  >
                    <a
                    @click="toggleMenuItem(item.withdrawId)"
                      class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary show menu-dropdown"
                      data-kt-menu-trigger="click"
                      data-kt-menu-placement="bottom-end"
                      >{{ this.$NOLIMITResource[this.dataUser.language].SeeDetail }}<i class="ki-duotone ki-down fs-5 ms-1"></i
                    ></a>
                    <!--begin::Menu-->
                    <div
                      class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4"
                      :class="{
                        show: onShowMenu && currenOnshowId === item.withdrawId,
                      }"
                     
                      data-kt-menu="true"
                      @mouseleave="closeMenuItem"
                    >
                    
                       <!--begin::Menu item-->
                       <div class="menu-item px-3">
                        <a
                          @click="openModalUpdate(item.withdrawId)"
                          class="menu-link px-3"
                          > {{ this.$NOLIMITResource[this.dataUser.language].RefundHistory.RefundRound2 }}</a
                        >
                      </div>
                      <!--end::Menu item-->
                    </div>
                    <!--end::Menu-->
                  </td>
                  </tr>
                </tbody>
              </table>
              <div class="row">
                <div
                  class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"
                >
                  <div class="dataTables_length" id="kt_customers_table_length">
                    <label>
                      <v-select v-model="this.pageSize" id="styleSelect" :options="numberPerPage" label="value" ></v-select>
                    </label>
                  </div>
                </div>
                <div
                  class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"
                >
                  <div
                    class="dataTables_paginate paging_simple_numbers"
                    id="kt_customers_table_paginate"
                  >
                    <ul v-if="this.totalPage >= 4" class="pagination">
                      <li
                        class="paginate_button page-item previous"
                        :class="{ disabled: this.pageNumber == 1 }"
                        id="kt_customers_table_previous"
                      >
                        <a
                          @click="prevPage"
                                                  aria-controls="kt_customers_table"
                          data-dt-idx="0"
                          tabindex="0"
                          class="page-link"
                          ><i class="previous"></i
                        ></a>
                      </li>
                      <li
                        v-for="index in 4"
                        :key="index"
                        class="paginate_button page-item"
                        :class="{ active: pageNumber == this.firstPage + index }"
                      >
                        <a
                          @click="selectPage(this.firstPage + index)"
                                                  aria-controls="kt_customers_table"
                          data-dt-idx="1"
                          tabindex="0"
                          class="page-link"
                          >{{ this.firstPage + index }}</a
                        >
                      </li>
                      <li
                        class="paginate_button page-item next"
                        :class="{ disabled: this.pageNumber == this.totalPage }"
                        id="kt_customers_table_next"
                      >
                        <a
                          @click="nextPage"
                                                  aria-controls="kt_customers_table"
                          data-dt-idx="5"
                          tabindex="0"
                          class="page-link"
                          ><i class="next"></i
                        ></a>
                      </li>
                    </ul>
                    <ul v-if="this.totalPage < 4" class="pagination">
                      <li
                        class="paginate_button page-item previous"
                        :class="{ disabled: this.pageNumber == 1 }"
                        id="kt_customers_table_previous"
                      >
                        <a
                          @click="prevPage"
                                                  aria-controls="kt_customers_table"
                          data-dt-idx="0"
                          tabindex="0"
                          class="page-link"
                          ><i class="previous"></i
                        ></a>
                      </li>
                      <li
                        v-for="index in totalPage"
                        :key="index"
                        class="paginate_button page-item"
                        :class="{ active: pageNumber == this.firstPage + index }"
                      >
                        <a
                          @click="selectPage(this.firstPage + index)"
                                                  aria-controls="kt_customers_table"
                          data-dt-idx="1"
                          tabindex="0"
                          class="page-link"
                          >{{ this.firstPage + index }}</a
                        >
                      </li>
                      <li
                        class="paginate_button page-item next"
                        :class="{ disabled: this.pageNumber == this.totalPage }"
                        id="kt_customers_table_next"
                      >
                        <a
                          @click="nextPage"
                                                  aria-controls="kt_customers_table"
                          data-dt-idx="5"
                          tabindex="0"
                          class="page-link"
                          ><i class="next"></i
                        ></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!--end::Table-->
            </div>
            <!--end::Card body-->
          </div>
          <!--end::Card-->
          <!--begin::Modals-->
          <!--begin::Modal - User - Update-->
          <div
            class="modal fade show"
            :class="{ block: isShowModalUpdate }"
            id="kt_modal_add_customer"
            tabindex="-1"
            aria-hidden="true"
          >
            <!--begin::Modal dialog-->
            <div class="modal-dialog modal-dialog-centered mw-650px">
              <!--begin::Modal content-->
              <div class="modal-content">
                <!--begin::Form-->
                <form
                  class="form"
                  id="kt_modal_add_customer_form"
                  action=""
                  @submit.prevent="updateWithdraw"
                >
                  <!--begin::Modal header-->
                  <div class="modal-header" id="kt_modal_add_customer_header">
                    <!--begin::Modal title-->
                    <h2 class="fw-bold"> {{ this.$NOLIMITResource[this.dataUser.language].RefundHistory.RefundRound2 }}</h2>
                    <!--end::Modal title-->
                    <!--begin::Close-->
                    <div
                      @click="closeModalUpdate"
                      id="kt_modal_add_customer_close"
                      class="btn btn-icon btn-sm btn-active-icon-primary"
                    >
                      <i class="ki-duotone ki-cross fs-1">
                        <span class="path1"></span>
                        <span class="path2"></span>
                      </i>
                    </div>
                    <!--end::Close-->
                  </div>
                  <!--end::Modal header-->
                  <!--begin::Modal body-->
                  <div class="modal-body py-10 px-lg-17">
                    <!--begin::Scroll-->
                    <div
                      class="scroll-y me-n7 pe-7"
                      id="kt_modal_add_customer_scroll"
                      data-kt-scroll="true"
                      data-kt-scroll-activate="{default: false, lg: true}"
                      data-kt-scroll-max-height="auto"
                      data-kt-scroll-dependencies="#kt_modal_add_customer_header"
                      data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
                      data-kt-scroll-offset="300px"
                    >
                      <!--begin::Input group-->
                      <div class="d-flex flex-column mb-7 fv-row">
                        <!--begin::Label-->
                        <label
                          class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                        >
                          <span>{{ this.$NOLIMITResource[this.dataUser.language].RefundHistory.AmountRefundRound2 }}</span>
                          <span
                            class="ms-1"
                            data-bs-toggle="tooltip"
                            :title="this.$NOLIMITResource[this.dataUser.language].RefundHistory.AmountRefundRound2"
                          >
                            <i
                              class="ki-duotone ki-information-5 text-gray-500 fs-6"
                            >
                              <span class="path1"></span>
                              <span class="path2"></span>
                              <span class="path3"></span>
                            </i>
                          </span>
                        </label>
                        <!--end::Label-->
                        <input
                          type="text"
                          class="form-control form-control-solid"
                          placeholder=""
                          name="card_name"
                          v-model="currentWithdraw.reconciliationRefundAmount"
                        />
                      </div>
                      <!--end::Input group-->
                    
                    </div>
                    <!--end::Scroll-->
                  </div>
                  <!--end::Modal body-->
                  <!--begin::Modal footer-->
                  <div class="modal-footer flex-center">
                    <!--begin::Button-->
                    <button
                      @click="closeModalUpdate"
                      type="reset"
                      id="kt_modal_add_customer_cancel"
                      class="btn btn-light me-3"
                    >
                    {{ this.$NOLIMITResource[this.dataUser.language].Cancel }}
                    </button>
                    <!--end::Button-->
                    <!--begin::Button-->
                    <button
                      type="submit"
                      id="kt_modal_add_customer_submit"
                      class="btn btn-primary"
                    >
                      <span class="indicator-label">{{ this.$NOLIMITResource[this.dataUser.language].Add }}</span>
                      <span class="indicator-progress"
                        >Please wait...
                        <span
                          class="spinner-border spinner-border-sm align-middle ms-2"
                        ></span
                      ></span>
                    </button>
                    <!--end::Button-->
                  </div>
                  <!--end::Modal footer-->
                </form>
                <!--end::Form-->
              </div>
            </div>
            
          </div>
          <!--end::Modal - User - Update-->
         
        </div>
        <!--end::Content container-->
      </div>
      <!--end::Content-->
    </div>
  </template>
  <style>
 
th,td
 {
    position: relative;
  }
  th{
    cursor: pointer;
  }
th div::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 10px;
  cursor: col-resize;
  background-color: #f4f4f4;
  display: none;
}
th:hover div::after{
  display: block;
}

.table {
    overflow: scroll;
    height: calc(100vh - 275px);
    display: block;
  }
</style>
  <style scoped>

  .table thead th:last-child{
    height: 70px;
  }
  .active__update__combobox {
    z-index: 555 !important;
  }
  .menu {
    left: -20px;
    top: 53px;
    position: relative;
  }
  td {
    text-align: start;
  }
  .menu-sub-dropdown {
    position: absolute;
  }
  .block {
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .loading-1 {
    height: 500px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 50px;
  }
  .show-dropfilter{
    z-index: 107; position: absolute; inset: 0px 0px auto auto; margin: 0px;
    top: 90px;
    right: 20px;
    text-align: start;
  }
 
  </style>
  <script>
    import router from '@/router/router.js';
  import { mapActions ,mapMutations,mapGetters} from "vuex";
  export default {
    name: "AllRefund",
    data() {
      return {
        dataUser:{language:"VN"},
        currentIndex: null,
        currentProperty: null,
        sortingAsc: null,
        sortingNumber: null,
        isResizing: false,
        initialX: 0,
        initialWidth: 0,
        resizingCol: null,
        columns: [
            { label: 'UserID', width: 250, property: "UserId" },
            { label: 'FacebookAdsID', width: 250, property: "FacebookAdsID" },
            { label: this.$NOLIMITResource["VN"].RefundHistory.Status, width: 250, property: "Status" },
            { label: this.$NOLIMITResource["VN"].RefundHistory.CreatedDate, width: 250, property: "CreatedDate" },
            { label: this.$NOLIMITResource["VN"].RefundHistory.UpdateDate, width: 250, property: "UpdateDate" },
            { label: this.$NOLIMITResource["VN"].RefundHistory.Spent, width: 250, property: "Spent" },
            { label: this.$NOLIMITResource["VN"].RefundHistory.SpentLimit, width: 250, property: "SpentLimit" },
            { label: this.$NOLIMITResource["VN"].RefundHistory.RemainingAmount, width: 250, property: "RemainingAmount" },
            { label: this.$NOLIMITResource["VN"].RefundHistory.CurrentRefundAmount, width: 250, property: "CurrentRefundAmount" },
            { label: this.$NOLIMITResource["VN"].RefundHistory.ReconciliationRefundAmount, width: 250, property: "ReconciliationRefundAmount" },
            { label: this.$NOLIMITResource["VN"].RefundHistory.TotalRefundAmount, width: 250, property: "TotalRefundAmount" },
        ],
        totalStatusProcessing:0,
        totalStatusFailed:0,
        totalStatusSuccess:0,
        numberPerPage:[10,25,50,100],
        topUpOrder:{},
        idUser:0,
        isAdmin:true,
        onLoading: true,
        withDraw:[],
        onShowMenu: false,
        currenOnshowId: 0,
        currentWithdraw: {},
        topUpOrdersAdd:{},
        currentBalanceUser: 0,
        currentTotalMoneyUser: 0,
        isShowModalUpdate: false,
        changeMoney: 0,
        formMode: "",
        pageSize: 10,
        pageNumber: 1,
        totalPage: 0,
        firstPage: 0,
        inputValue: "",
        facebookAdsIds: [],
        hasData:true,
        status:"",
      dateStart: null,
      dateEnd: null,
      timezoneIndex: 20,
        timezoneIndex1: 1,
        timezones: [
          'Pacific/Midway',      // -11
          'Pacific/Niue',        // -11
          'Pacific/Honolulu',    // -10
          'Pacific/Pago_Pago',   // -11
          'America/Anchorage',   // -9
          'America/Los_Angeles', // -8
          'America/Tijuana',     // -8
          'America/Denver',      // -7
          'America/Phoenix',     // -7
          'America/Chicago',      // -6
          'America/Mexico_City', // -6
          'America/Regina',      // -6
          'America/New_York',    // -5
          'America/Caracas',     // -4.5
          'America/Halifax',     // -4
          'America/Argentina/Buenos_Aires', // -3
          'America/Sao_Paulo',   // -3
          'Atlantic/Azores',     // -1
          'Atlantic/Cape_Verde', // -1
          'UTC',                 // 0
          'Europe/London',       // +0
          'Europe/Paris',        // +1
          'Europe/Istanbul',     // +3
          'Europe/Moscow',       // +3
          'Asia/Dubai',          // +4
          'Asia/Yekaterinburg',  // +5
          'Asia/Almaty',         // +6
          'Asia/Colombo',        // +6
          'Asia/Bangkok',        // +7
          'Asia/Ho_Chi_Minh',    // +7
          'Asia/Jakarta',        // +7
          'Asia/Taipei',         // +8
          'Asia/Singapore',      // +8
          'Asia/Kuala_Lumpur',   // +8
          'Asia/Hong_Kong',      // +8
          'Asia/Ulaanbaatar',    // +8
          'Asia/Pyongyang',      // +9
          'Asia/Tokyo',          // +9
          'Australia/Darwin',    // +9.5
          'Australia/Adelaide',  // +10
          'Australia/Sydney',    // +10
          'Pacific/Guam',        // +10
          'Australia/Brisbane',  // +10
          'Asia/Vladivostok',    // +10
          'Pacific/Fiji',        // +12
          'Pacific/Tongatapu',   // +13
          'Pacific/Apia',        // +13
        ],
      };
    },
    watch: {
      dataUser:{
      handler: function() {
        this.columns = [
            { label: 'UserID', width: 250, property: "UserId" },
            { label: 'FacebookAdsID', width: 250, property: "FacebookAdsID" },
            { label: this.$NOLIMITResource[this.dataUser.language].RefundHistory.Status, width: 250, property: "Status" },
            { label: this.$NOLIMITResource[this.dataUser.language].RefundHistory.CreatedDate, width: 250, property: "CreatedDate" },
            { label: this.$NOLIMITResource[this.dataUser.language].RefundHistory.UpdateDate, width: 250, property: "UpdateDate" },
            { label: this.$NOLIMITResource[this.dataUser.language].RefundHistory.Spent, width: 250, property: "Spent" },
            { label: this.$NOLIMITResource[this.dataUser.language].RefundHistory.SpentLimit, width: 250, property: "SpentLimit" },
            { label: this.$NOLIMITResource[this.dataUser.language].RefundHistory.RemainingAmount, width: 250, property: "RemainingAmount" },
            { label: this.$NOLIMITResource[this.dataUser.language].RefundHistory.CurrentRefundAmount, width: 250, property: "CurrentRefundAmount" },
            { label: this.$NOLIMITResource[this.dataUser.language].RefundHistory.ReconciliationRefundAmount, width: 250, property: "ReconciliationRefundAmount" },
            { label: this.$NOLIMITResource[this.dataUser.language].RefundHistory.TotalRefundAmount, width: 250, property: "TotalRefundAmount" },
        ]
      }
    },
      pageSize() {
      this.reDisplay();
    },
        'topUpOrder.amount': function(newAmount) {
        const amountNumber = parseFloat(newAmount);
        // Tính toán fee dựa trên 3% của newAmount
        this.topUpOrder.fee = (newAmount * 0.03).toFixed(2); // Làm tròn đến 2 chữ số thập phân
        this.topUpOrder.amountDisplay = (amountNumber + parseFloat(this.topUpOrder.fee)).toString() // Làm tròn đến 2 chữ số thập phân
        // Nếu bạn muốn fee là số nguyên, bạn có thể sử dụng:
        // this.topUpOrder.fee = Math.round(newAmount * 0.03);
        },
        
    },
    computed:{
      ...mapGetters("listModule", [
      "onShowMenuFilter",
      "formatCurrency",
      "formatDateTime",
    ]),
 
    timezone2() {
      return this.timezones[this.timezoneIndex];
    },
    timezone1() {
      return this.timezones[this.timezoneIndex1];
    },
    attributes() {
      return[ {
        highlight: true,
        dates: this.dateStart,
      }];
    },
  },


  
  async created() {
 
  await this.$apiService
    .getUserProfile().then((response) => {
      if(response.data.data.language == "VN" || response.data.data.language == "EN"){
          this.dataUser = response.data.data;
      }else{
          this.dataUser = response.data.data;
          this.dataUser.language = "VN";
      }
        this.idUser = response.data.data.userId
        if(response.data.data.isAdmin == false && response.data.data.isInternalAccountant != true){
                this.isAdmin=false
                router.push({ name: 'ErrorNotFound' });
            }
        this.$apiService
            .getAllWithdrawByAdmin(this.pageNumber, this.pageSize)
            .then((res) => {
            setTimeout(() => {
                this.onLoading = false;
            }, 1000);
            this.withDraw = res.data.data;
            if(res.data.data.length == 0){
                this.hasData = false;
              }
           
            this.totalPage = res.data.pageData.pageCount;
            })
            .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
            });

            })
    .catch((error) => {
          console.error("Lỗi khi lấy dữ liệu:", error);
        });
    },
   
    methods: {
      ...mapActions("listModule", ["swalSuccess","swalError","toggleMenuFilter"]),
      ...mapMutations("listModule", ["closeMenuFilter"]),
      openModalUpdate(id) {
        
        this.isShowModalUpdate = true;
        this.$apiService
          .getWithdrawById(id)
          .then((res) => {
            this.currentWithdraw = res.data.data;
            if(res.data.data.status == "Hoàn thành"){
              this.swalError({ message: "Đã hoàn tiền đợt 2 không thể hoàn nữa!", language: this.dataUser.language})
              this.isShowModalUpdate = false;
              this.onShowMenu = false;
            }
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      },
      closeModalUpdate() {
        this.isShowModalUpdate = false;
        this.onShowMenu = false;
      },
      toggleMenuItem(id) {
        this.onShowMenu = !this.onShowMenu;
        this.currenOnshowId = id;
        
      },
      selectPage(index) {
        this.pageNumber = index;
        if (this.totalPage >= 4) {
          if (index == this.totalPage) {
            this.firstPage = index - 4;
          } else if (index >= 3) {
            this.firstPage = index - 3;
          } else {
            this.firstPage = 0;
          }
        }
        if (this.status != "" || this.dateStart != null || this.dateEnd != null) {
          this.onFilterSearch();
        } else if (this.sortingNumber != null || this.sortingAsc != null) {
          this.onSorting(this.currentIndex, this.currentProperty, "selectPage");
        } else {
          this.onEnterSearch();
        }
      },
      reDisplay() {
        this.hasData = true;
        this.$apiService
        .getAllWithdrawByAdmin(this.pageNumber, this.pageSize)
        .then((res) => {
          
          this.onLoading = true;
              setTimeout(() => {
                this.onLoading = false;
              }, 1000);
          this.withDraw = res.data.data;
          if(res.data.data.length == 0){
                this.hasData = false;
              }
          this.totalPage = res.data.pageData.pageCount;
        })
        .catch((error) => {
          console.error("Lỗi khi lấy dữ liệu:", error);
        });
      },
      /**
       * Chọn next trang tiếp theo trong mục phân trang
       * Author: ndanh (20/09/2023)
       */
      nextPage() {
        if (this.pageNumber != this.totalPage) {
          this.pageNumber += 1;
          if (this.totalPage >= 4) {
            if (this.pageNumber == this.totalPage) {
              this.firstPage = this.pageNumber - 4;
            } else if (this.pageNumber >= 3) {
              this.firstPage = this.pageNumber - 3;
            } else {
              this.firstPage = 0;
            }
          }
        }
        if (this.status != "" || this.dateStart != null || this.dateEnd != null) {
          this.onFilterSearch();
        } else if (this.sortingNumber != null || this.sortingAsc != null) {
          this.onSorting(this.currentIndex, this.currentProperty, "selectPage");
        } else {
          this.onEnterSearch();
        }
      },
      /**
       * Chọn prev trang trước đó trong mục phân trang
       * Author: ndanh (20/09/2023)
       */
      prevPage() {
        if (this.pageNumber != 1) {
          this.pageNumber -= 1;
          if (this.totalPage >= 4) {
            if (this.pageNumber == this.totalPage) {
              this.firstPage = this.pageNumber - 4;
            } else if (this.pageNumber >= 3) {
              this.firstPage = this.pageNumber - 3;
            } else {
              this.firstPage = 0;
            }
          }
        }
        if (this.status != "" || this.dateStart != null || this.dateEnd != null) {
          this.onFilterSearch();
        } else if (this.sortingNumber != null || this.sortingAsc != null) {
          this.onSorting(this.currentIndex, this.currentProperty, "selectPage");
        } else {
          this.onEnterSearch();
        }
      },
      onEnterSearch() {
        this.hasData = true;
        if (this.inputValue == "") {
          this.reDisplay();
        } else {
          this.$apiService
            .getAllWithdrawByAdminSearch(
              this.pageNumber,
              this.pageSize,
              this.inputValue
            )
            .then((res) => {
              this.onLoading = true;
              setTimeout(() => {
                this.onLoading = false;
              }, 1000);
              this.withDraw = res.data.data;
              if(res.data.data.length == 0){
                this.hasData = false;
              }
              this.totalPage = res.data.pageData.pageCount;
            })
            .catch((error) => {
              console.error("Lỗi khi lấy dữ liệu:", error);
            });
        }
      },
      onEnterSearchFirst() {
        this.hasData = true;
        if (this.inputValue == "") {
          this.reDisplay();
        } else {
        this.pageNumber = 1;
        this.firstPage = 0;
          this.$apiService
            .getAllWithdrawByAdminSearch(
              this.pageNumber,
              this.pageSize,
              this.inputValue
            )
            .then((res) => {
              this.onLoading = true;
              setTimeout(() => {
                this.onLoading = false;
              }, 1000);
              this.withDraw = res.data.data;
              if(res.data.data.length == 0){
                this.hasData = false;
              }
              this.totalPage = res.data.pageData.pageCount;
            })
            .catch((error) => {
              console.error("Lỗi khi lấy dữ liệu:", error);
            });
        }
      },
      choosePageSize(event) {
        this.pageSize = event.target.value;
        this.reDisplay();
      },
   

   
    
      convertToEndOfDay(date) {
      date.setHours(23, 59, 59, 999);

      // Lấy các thành phần ngày giờ
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
      const milliseconds = date.getMilliseconds().toString().padStart(3, '0');

      // Tạo chuỗi ISO
      const isoString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
      return isoString;
      },
      onResetFilter(){
        this.status="";
        this.dateStart = null;
        this.dateEnd = null;
      },
      startResize(event, index) {
        if (event.offsetX > event.target.offsetWidth - 10) {
                this.isResizing = true;
                this.initialX = event.clientX;
                this.initialWidth = this.columns[index].width;
                this.resizingCol = index;

                document.addEventListener('mousemove', this.handleMouseMove);
                document.addEventListener('mouseup', this.handleMouseUp);
        } 
      },
      handleMouseMove(event) {
          if (this.isResizing && this.resizingCol !== null) {
              const delta = event.clientX - this.initialX;
              const newWidth = Math.max(50, this.initialWidth + delta);
              this.columns[this.resizingCol] = { ...this.columns[this.resizingCol], width: newWidth };
          }
      },
      handleMouseUp() {
          this.isResizing = false;
          this.initialX = 0;
          this.initialWidth = 0;
          this.resizingCol = null;

          document.removeEventListener('mousemove', this.handleMouseMove);
          document.removeEventListener('mouseup', this.handleMouseUp);
      },
      onSorting(index, property, mode) {
      this.currentIndex = index;
      this.currentProperty = property;
      if (this.sortingNumber == index && mode == "changeSort") {
        this.sortingNumber = null;
        this.sortingAsc = index;
        this.$apiService
          .getWithdrawSortAscByAdmin(
            this.pageNumber,
            this.pageSize,
            property
          )
          .then((res) => {
           
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.withDraw = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      } else if (this.sortingNumber == index && mode == "selectPage") {
        this.sortingNumber = null;
        this.sortingAsc = null;
        this.sortingNumber = index;
        this.$apiService
          .getWithdrawSortDescByAdmin(
            this.pageNumber,
            this.pageSize,
            property
          )
          .then((res) => {
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.withDraw = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      } else if (this.sortingAsc == index && mode == "changeSort") {
        this.sortingAsc = null;
        this.sortingNumber = index;
        this.$apiService
          .getWithdrawSortDescByAdmin(
            this.pageNumber,
            this.pageSize,
            property
          )
          .then((res) => {
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.withDraw = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      } else if (this.sortingAsc == index && mode == "selectPage") {
        this.sortingAsc = null;
        this.sortingNumber = null;
        this.sortingAsc = index;
        this.$apiService
          .getWithdrawSortAscByAdmin(
            this.pageNumber,
            this.pageSize,
            property
          )
          .then((res) => {
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.withDraw = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      } else {
        this.sortingNumber = null;
        this.sortingAsc = null;
        this.sortingNumber = index;
        this.$apiService
          .getWithdrawSortDescByAdmin(
            this.pageNumber,
            this.pageSize,
            property
          )
          .then((res) => {
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.withDraw = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      }
    },
    formatCurrencyValue(value) {
      // Chuyển đổi số thành chuỗi
      let stringValue = value.toString();

      // Chia chuỗi thành mảng các nhóm mỗi 3 chữ số
      let parts = stringValue.split('.');
      let mainPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

      // Nếu có phần thập phân, thêm vào chuỗi
      let result = parts.length > 1 ? mainPart + ',' + parts[1] : mainPart;

      return result;
    },
    updateWithdraw(){
      this.currentWithdraw.status = "Hoàn thành";
      this.currentWithdraw.totalRefundAmount += parseInt(this.currentWithdraw.reconciliationRefundAmount);
      this.$apiService.updateWithdraw(this.currentWithdraw).then(()=>{
        this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].RefundHistory.SuccessUpdateRefundRound2);
        this.closeModalUpdate();
        this.on
      })
    }
    },
  };
  </script>
  